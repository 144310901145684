<template>
  <div class="max-w-xs mx-auto">
    <div class="bg-white shadow-md border border-gray-400 rounded-lg max-w-sm mb-3">
      <div class="p-5">
        <h5 class="text-gray-900 font-bold text-2xl text-center tracking-tight mb-2">
          Olvidé mi contraseña
        </h5>
        <p class="mb-4 text-center">
          Escribe tu correo para que te enviemos un código de restauración.
        </p>
        <a-form layout="vertical" :model="formState" :rules="rules" ref="formRef">
          <a-form-item name="correo">
            <a-input v-model:value="formState.correo" placeholder="Correo electrónico">
              <template #prefix><MailOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
            </a-input>
          </a-form-item>
          <a-button
            type="primary"
            class="font-bold text-xs"
            htmlType="submit"
            :loading="isLoading"
            block
            @click="onSubmit"
            >SOLICITAR NUEVA CONTRASEÑA</a-button
          >

          <a-alert v-if="hasError" class="mt-4" :description="hasError" type="error" closable />

          <a-button type="link" block class="mt-2 font-semibold text-xs">
            <router-link to="login">IR A INICIAR SESIÓN</router-link>
          </a-button>
        </a-form>
      </div>
    </div>
  </div>
  <!-- end card -->
</template>

<script>
import { reactive, toRaw, ref } from "vue";
import { MailOutlined } from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import { useStore } from "vuex";

export default {
  components: {
    MailOutlined
  },
  setup() {
    const formRef = ref();
    const isLoading = ref(false);
    const hasError = ref("");
    const store = useStore();

    const formState = reactive({
      isFromAdmin: 0,
      correo: ""
    });

    const onSubmit = () => {
      hasError.value = "";
      formRef.value
        .validate()
        .then(async () => {
          const payload = toRaw(formState);

          isLoading.value = true;

          store
            .dispatch("user/forgot", payload)
            .then(() => {
              notification.success({
                message: "Correo electrónico enviado"
              });
            })
            .catch((error) => {
              console.log(error);
              hasError.value = error.message;
            })
            .finally(() => (isLoading.value = false));
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    // rules
    const rules = {
      correo: [
        { required: true, message: "El campo correo electrónico es requerido", trigger: "blur" },
        {
          max: 45,
          message: "El correo debe a lo mucho de 45 caracteres",
          trigger: "blur"
        },
        {
          type: "email",
          message: "Formato de correo inválido"
        }
      ]
    };
    // end rules

    return {
      formRef,
      formState,
      onSubmit,
      rules,
      isLoading,
      hasError
    };
  }
};
</script>

<style></style>
